<template>
  <div class="content-wrap">
    <!------------------------------ 左部分区域 start ------------------------------>
    <div class="content-left">
      <!-- 水产饲料情况 -->
      <div class="content-item aquatic-feed-situation">
        <div class="content-title">
          <span>水产饲料情况</span>
        </div>
        <div class="content-charts">
          <div class="charts-item">
            <div class="item-val">
              <CountTo
                :key="1"
                :start-val="0"
                :end-val="+infoData.fodderCompany || 0"
                :duration="2500"
              ></CountTo>
            </div>
            <div class="item-txt">饲料企业/家</div>
          </div>
          <div class="charts-item">
            <div class="item-val">
              <CountTo
                :key="1"
                :start-val="0"
                :end-val="+infoData.fodderFactory || 0"
                :duration="2500"
              ></CountTo>
            </div>
            <div class="item-txt">饲料厂/家</div>
          </div>
          <div class="charts-item">
            <div class="item-val">
              <CountTo
                :key="2"
                :start-val="0"
                :end-val="+infoData.fodderWorkshop || 0"
                :duration="2500"
              ></CountTo>
            </div>
            <div class="item-txt">饲料车间/平方米</div>
          </div>
        </div>
      </div>
      <!-- 水产饲料年产量与产值趋势 -->
      <div class="content-item aquatic-trend">
        <div class="content-title">
          <span>水产饲料年产量与产值趋势</span>
        </div>
        <div
          class="content-charts"
          v-loading="!infoData.fodderProductionLineBarChart.lineChart"
          element-loading-background="transparent"
        >
          <div id="aquatic-trend-chart" style="width: 100%; height: 100%"></div>
        </div>
      </div>
      <!-- 水产饲料月产量 -->
      <div class="content-item monthly-output">
        <div class="content-title">
          <span>水产饲料月产量</span>
        </div>
        <div
          class="content-charts"
          v-loading="!infoData.fodderProductionLineBarChart.lineChart"
          element-loading-background="transparent"
        >
          <div
            id="monthly-output-chart"
            style="width: 100%; height: 100%"
          ></div>
        </div>
      </div>

      <!-- 饲料产品价格走势 -->
      <div class="content-item product-price-trend">
        <div class="content-title">
          <span>饲料产品价格走势</span>
        </div>
        <div
          class="content-charts"
          v-loading="!infoData.fodderPriceLineMoreChart.list.length"
          element-loading-background="transparent"
        >
          <div
            id="product-price-trend-chart"
            style="width: 100%; height: 100%"
          ></div>
        </div>
      </div>
    </div>
    <!------------------------------ 左部分区域 end ------------------------------>

    <!------------------------------ 中部分区域 start ------------------------------>
    <div class="content-middle">
      <!-- 年总产值 -->
      <div class="total-num">
        <div class="charts-item">
          <div class="item-val">
            <CountTo
              :key="1"
              :start-val="0"
              :end-val="+infoData.annualOutputValue || 0"
              :duration="2500"
            ></CountTo>
          </div>
          <div class="item-txt">饲料年产值/亿元</div>
        </div>
        <div class="charts-item">
          <div class="item-val">
            <CountTo
              :key="1"
              :start-val="0"
              :end-val="+infoData.annualOutput || 0"
              :duration="2500"
            ></CountTo>
          </div>
          <div class="item-txt">饲料年产量/万吨</div>
        </div>
        <div class="charts-item">
          <div class="item-val">
            <CountTo
              :key="2"
              :start-val="0"
              :end-val="+infoData.processingOutputValue || 0"
              :duration="2500"
            ></CountTo>
          </div>
          <div class="item-txt">加工产值/亿元</div>
        </div>
        <div class="charts-item">
          <div class="item-val">
            <CountTo
              :key="20"
              :start-val="0.1"
              :end-val="+infoData.processingCapability || 0"
              :duration="2500"
            ></CountTo>
          </div>
          <div class="item-txt">加工能力/万吨</div>
        </div>
      </div>
    </div>
    <!------------------------------ 中部分区域 end ------------------------------>

    <!------------------------------ 右部分区域 start ------------------------------>
    <div class="content-right">
      <!-- 生产加工情况 -->
      <div class="content-item production-processing">
        <div class="content-title">
          <span>生产加工情况</span>
        </div>
        <div class="content-charts">
          <div class="charts-item">
            <div class="item-val">
              <CountTo
                :key="1"
                :start-val="0"
                :end-val="+infoData.processingCompany || 0"
                :duration="2500"
              ></CountTo>
            </div>
            <div class="item-txt">食品加工企业/家</div>
          </div>
          <div class="charts-item">
            <div class="item-val">
              <CountTo
                :key="1"
                :start-val="0"
                :end-val="+infoData.processingWorkshop || 0"
                :duration="2500"
              ></CountTo>
            </div>
            <div class="item-txt">加工车间/平方米</div>
          </div>
          <div class="charts-item">
            <div class="item-val">
              <CountTo
                :key="2"
                :start-val="0"
                :end-val="+infoData.productionLine || 0"
                :duration="2500"
              ></CountTo>
            </div>
            <div class="item-txt">生产线/条</div>
          </div>
        </div>
      </div>
      <!-- 生产加工年产量与产值趋势 -->
      <div class="content-item output-trend">
        <div
          class="content-title"
          v-loading="!infoData.processingProductionLineBarChart.lineChart"
          element-loading-background="transparent"
        >
          <span>生产加工年产量与产值趋势</span>
        </div>

        <div class="content-charts">
          <div id="output-trend-chart" style="width: 100%; height: 100%"></div>
        </div>
      </div>
      <!-- 生产加工月产量 -->
      <div class="content-item processing-output">
        <div class="content-title">
          <span>生产加工月产量</span>
        </div>
        <div
          class="content-charts"
          v-loading="!infoData.fodderProductionLineBarChart.lineChart"
          element-loading-background="transparent"
        >
          <div
            id="processing-output-chart"
            style="width: 100%; height: 100%"
          ></div>
        </div>
      </div>
      <!-- 加工品种种类 -->
      <div class="content-item processed-varieties">
        <div class="content-title">
          <span>加工品种种类</span>
        </div>
        <div
          class="content-charts"
          v-loading="!infoData.processBreedPieChildrenChart.length"
          element-loading-background="transparent"
        >
          <div
            id="processed-varieties-chart"
            style="width: 100%; height: 100%"
          ></div>
        </div>
      </div>
    </div>
    <!------------------------------ 右部分区域 end ------------------------------>
  </div>
</template>
<script>
import Vue from 'vue'
import { Progress } from 'element-ui'
Vue.use(Progress)

import CountFlop from '../../../components/count-to-component.vue'
import containerTab from '../../../components/container-tab.vue'

import * as $echarts from 'echarts'
import CountTo from 'vue-count-to'
export default {
  name: 'feed-processing',
  components: {
    // videoDemo,
    CountFlop,
    CountTo,
    containerTab,
  },
  inject: ['app'],
  data() {
    return {
      infoData: {
        aquaticFeedMonthProductionList: [], // 水产饲料月产量
        fodderPriceLineMoreChart: { list: [] }, //饲料产品价格走势
        fodderProductionLineBarChart: {}, // 	水产饲料年产量与产值趋势
        processBreedPieChildrenChart: [], // 	加工品种种类
        processingMonthProductionList: [], // 生产加工月产量
        processingProductionLineBarChart: {}, //生产加工年产量与产值趋势
      },
      timerNum: this.$.formatDate(new Date(), 'yyyy-MM-dd hh:mm'),
      // 饲料产品价格走势
      product: {
        idName: 'product-price-trend-chart',
        xAxis: [],
        series: [],
        legend: [],
      },
      // 水产饲料年产量与产值趋势
      aquatic: {
        idName: 'aquatic-trend-chart',
        series: {
          data: [],
        },
        xAxis: {
          data: ['2016年', '2017年', '2018年', '2019年', '2020年', '2021年'],
        },
      },
      // 水产饲料月产量
      monthly: {
        idName: 'monthly-output-chart',
        yAxis: {
          data: ['8月', '7月', '6月', '5月', '4月', '3月', '2月', '1月'],
        },
        legend: ['进口总额'],
        series: [],
      },
      // 生产加工年产量与产值趋势
      output: {
        idName: 'output-trend-chart',
        xAxis: {
          data: ['2016年', '2017年', '2018年', '2019年', '2020年', '2021年'],
        },
        series: {
          data: [],
        },
      },
      // 生产加工月产量
      processing: {
        idName: 'processing-output-chart',
        legend: ['进口总额'],
        yAxis: {
          data: ['8月', '7月', '6月', '5月', '4月', '3月', '2月', '1月'],
        },
        series: [],
      },
      // 加工品种种类
      processed: {
        idName: 'processed-varieties-chart',
        series: [],
      },
    }
  },
  computed: {},
  methods: {
    // 折线图
    aquaticTrendChart(obj) {
      let fontHandle = this.app.fontHandle
      let chart = $echarts.init(document.getElementById(obj.idName))
      let option = {
        backgroundColor: 'transparent',
        tooltip: {
          trigger: 'axis',
        },
        color: ['#b2f4f8', '#ffcb4d', '#12fffe'],
        legend: {
          icon: 'roundRect', // 有点圆角的图例标志
          data: ['产值', '产量'],
          bottom: fontHandle(6),
          textStyle: {
            color: '#9BA7BF',
            fontSize: fontHandle(12),
          },
          itemWidth: fontHandle(10),
          itemHeight: fontHandle(10),
          itemGap: fontHandle(35),
          borderRadius: fontHandle(200), // need edit
        },
        grid: {
          left: fontHandle(11, 'px'),
          right: fontHandle(18, 'px'),
          bottom: fontHandle(30, 'px'),
          top: fontHandle(25, 'px'),
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: obj.xAxis.data,
            axisLine: {
              show: true,
              lineStyle: {
                color: '#46494A',
                opacity: 1,
                width: 1,
                type: 'solid',
              },
            },
            axisTick: {
              show: false,
            },
            // x轴下方样式
            axisLabel: {
              show: true,

              textStyle: {
                color: '#838DA1',
                fontSize: fontHandle(12),
              },
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            splitNumber: 3,
            nameTextStyle: {
              color: '#838DA1',
            },
            offset: 0,
            // Y轴下方样式
            axisLabel: {
              color: '#838DA1',
              fontSize: fontHandle(12),
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                type: [10, 10],
                color: '#46494A',
                width: 1,
              },
            },
          },
          {
            type: 'value',
            splitNumber: 3,
            nameTextStyle: {
              color: '#838DA1',
            },
            // Y轴下方样式
            axisLabel: {
              color: '#838DA1',
              fontSize: fontHandle(12),
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                type: [10, 10],
                color: '#46494A',
                width: 1,
              },
            },
          },
        ],
        series: [
          {
            name: '产量',
            type: 'line',
            itemStyle: {
              normal: {
                color: '#F553FB',
              },
            },
            lineStyle: {
              width: 2,
              color: '#F06CFA',
            },
            data: obj.series.data[0],
            smooth: true,
            symbolSize: 0,
          },
          {
            name: '产值',
            type: 'bar',
            yAxisIndex: 1,
            data: obj.series.data[1],
            barWidth: fontHandle(15),
            barGap: 1,
            itemStyle: {
              normal: {
                color: new $echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: '#6BE0BD',
                    },
                    {
                      offset: 0.5,
                      color: '#089DDD',
                    },
                    {
                      offset: 1,
                      color: '#FE69FB',
                    },
                  ],
                  false
                ),
                barBorderRadius: fontHandle(100),
              },
            },
          },
        ],
      }
      chart.setOption(option)
    },
    // 折线图2
    monthlyOutputChart(obj) {
      let fontHandle = this.app.fontHandle
      let chart = $echarts.init(document.getElementById(obj.idName))
      let option = {
        backgroundColor: 'transparent',
        tooltip: {
          trigger: 'axis',
        },
        color: ['#b2f4f8', '#ffcb4d', '#12fffe'],
        legend: {
          show: false,
          data: obj.legend.data,
          bottom: fontHandle(10),
          textStyle: {
            color: '#fff',
            fontSize: fontHandle(12),
          },
          icon: 'circle',
          itemWidth: fontHandle(16),
          itemHeight: fontHandle(16),
          itemGap: fontHandle(35),
        },
        grid: {
          left: fontHandle(11, 'px'),
          right: fontHandle(18, 'px'),
          bottom: fontHandle(21, 'px'),
          top: fontHandle(25, 'px'),
          containLabel: true,
        },
        xAxis: [
          {
            type: 'value',
            nameTextStyle: {
              color: '#ffffff',
            },
            offset: 0,
            axisLabel: {
              color: '#838DA1',
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#12fffe',
                width: 0,
                type: 'solid',
              },
            },
            splitLine: {
              lineStyle: {
                type: [10, 10],
                color: '#46494A',
                width: 1,
              },
            },
            axisLabel: {
              show: true,

              textStyle: {
                color: '#838DA1',
                fontSize: fontHandle(12),
              },
            },
          },
        ],
        yAxis: [
          {
            type: 'category',
            data: obj.yAxis.data,
            axisLine: {
              show: true,
              lineStyle: {
                color: '#46494A',
                width: 1,
                type: 'solid',
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: true,

              textStyle: {
                color: '#838DA1',
                fontSize: fontHandle(12),
              },
            },
          },
        ],
        series: [
          {
            name: '产量:',
            type: 'bar',
            data: obj.series.data,
            barWidth: fontHandle(10),
            barGap: 1,
            itemStyle: {
              normal: {
                barBorderRadius: fontHandle(10),
                color: new $echarts.graphic.LinearGradient(
                  1,
                  0,
                  0,
                  0,
                  [
                    {
                      offset: 0,
                      color: '#6BE0BD',
                    },
                    {
                      offset: 0.5,
                      color: '#089DDD',
                    },
                    {
                      offset: 1,
                      color: '#FE69FB',
                    },
                  ],
                  false
                ),
              },
            },
          },
        ],
      }
      chart.setOption(option)
    },
    // 饲料产品价格走势
    productPriceTrend(obj) {
      let fontHandle = this.app.fontHandle
      let chart = $echarts.init(document.getElementById(obj.idName))
      let seriescolor = [
        '#9287FF',
        '#3CB8FF',
        '#EDF6AD',
        '#5B49FF',
        '#F55AFB',
        '#5DF7E4',
        '#80EBD5',
        '#33CEFA',
      ]
      let series = obj.legend.map((ele, idx) => {
        return {
          name: ele,
          type: 'line',
          itemStyle: {
            normal: {
              color: seriescolor[idx],
            },
          },
          lineStyle: {
            width: 2.5,
            color: seriescolor[idx],
          },
          data: obj.series[idx],
          smooth: true,
          symbolSize: 0,
        }
      })

      let option = {
        backgroundColor: 'transparent',
        tooltip: {
          trigger: 'axis',
        },
        color: ['#b2f4f8', '#ffcb4d', '#12fffe'],
        legend: {
          icon: 'roundRect', // 有点圆角的图例标志
          data: obj.legend,
          bottom: fontHandle(6),
          textStyle: {
            color: '#9BA7BF',
            fontSize: fontHandle(12),
          },
          itemWidth: fontHandle(10),
          itemHeight: fontHandle(10),
          itemGap: fontHandle(35),
        },
        grid: {
          left: fontHandle(11, 'px'),
          right: fontHandle(18, 'px'),
          bottom: fontHandle(34, 'px'),
          top: fontHandle(25, 'px'),
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: obj.xAxis,
            axisLine: {
              show: true,
              lineStyle: {
                color: '#46494A',
                opacity: 1,
                width: 1,
                type: 'solid',
              },
            },
            axisTick: {
              show: false,
            },
            // x轴下方样式
            axisLabel: {
              show: true,

              textStyle: {
                color: '#838DA1',
                fontSize: fontHandle(12),
              },
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            nameTextStyle: {
              color: '#838DA1',
            },
            offset: 0,
            // Y轴下方样式
            axisLabel: {
              color: '#838DA1',
              fontSize: fontHandle(12),
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                type: [10, 10],
                color: '#46494A',
                width: 1,
              },
            },
          },
        ],
        series,
      }
      console.log('图表', option)
      chart.setOption(option)
    },
    // 玫瑰图
    processedVarietiesChart(obj) {
      let fontHandle = this.app.fontHandle
      let chart = $echarts.init(document.getElementById(obj.idName))
      var colors = ['#F553FB', '#5442FF', '#EA5151', '#CC3F57', '#9A2555']
      var bgColor = 'transparent'

      // var data2 = [
      //   {
      //     name: '三文鱼',
      //     itemStyle: { color: '#F553FB' },
      //     children: [
      //       {
      //         name: '三文鱼',
      //         value: 15,
      //         itemStyle: { color: '#F553FB' },
      //       },
      //       {
      //         itemStyle: { color: '#F553FB' },
      //         name: '三文鱼',
      //         value: 10,
      //       },
      //     ],
      //   },
      //   {
      //     name: '金鲳鱼',
      //     itemStyle: { color: '#5442FF' },
      //     children: [
      //       {
      //         itemStyle: { color: '#5442FF' },
      //         name: '金鲳鱼',
      //         value: 90,
      //       },
      //     ],
      //   },
      // ]

      obj.series.forEach((item, idx) => {
        item.itemStyle = { color: colors[idx] }
        if (item.children) {
          item.children.forEach((ele) => {
            ele.value = +ele.value
            ele.itemStyle = { color: colors[idx] }
          })
        }
      })
      console.log('dddd', obj.series)
      let option = {
        backgroundColor: bgColor,
        color: colors,
        series: [
          {
            type: 'sunburst',
            center: ['50%', '48%'],
            data: obj.series,
            sort: function (a, b) {
              if (a.depth === 1) {
                return b.getValue() - a.getValue()
              } else {
                return a.dataIndex - b.dataIndex
              }
            },
            label: {
              rotate: 'radial',
              color: bgColor,
            },
            itemStyle: {
              borderColor: '#F3F7FE',
              borderWidth: 2,
            },
            levels: [
              {},
              {
                r0: fontHandle(16),
                r: fontHandle(50),
                label: {
                  rotate: 'tangential',
                  color: '#fff',
                  fontSize: fontHandle(12),
                },
              },
              {
                r0: fontHandle(50),
                r: fontHandle(84),
                itemStyle: {},
                label: {
                  rotate: 0,
                  position: 'outside',
                  fontSize: fontHandle(14),
                  color: '#9BA7BF',
                },
                downplay: {
                  label: {
                    opacity: 0.5,
                  },
                },
              },
            ],
          },
        ],
      }

      chart.setOption(option)
    },
  },
  beforeMount() {
    // 模板编译/挂载之前
  },
  mounted() {
    // 模板编译/挂载之后
    this.$axios('/zhanjiang-dataview/viewFodderProcessing/queryInfo')
      .then((res) => {
        this.infoData = res.data
        let oneData = []
        let twoData = []
        this.aquatic.xAxis.data =
          res.data.fodderProductionLineBarChart.years.map((e) => e + '年')
        this.aquatic.series.data = [
          res.data.fodderProductionLineBarChart.lineChart,
          res.data.fodderProductionLineBarChart.barChart,
        ]
        this.output.xAxis.data =
          res.data.processingProductionLineBarChart.years.map((e) => e + '年')
        this.output.series.data = [
          res.data.processingProductionLineBarChart.lineChart,
          res.data.processingProductionLineBarChart.barChart,
        ]
        this.monthly.yAxis.data = res.data.aquaticFeedMonthProductionList.map(
          (ele) => ele.name + '月'
        )
        this.monthly.series.data = res.data.aquaticFeedMonthProductionList.map(
          (ele) => ele.value
        )

        this.processing.yAxis.data = res.data.processingMonthProductionList.map(
          (ele) => ele.name + '月'
        )
        this.processing.series.data =
          res.data.processingMonthProductionList.map((ele) => ele.value)

        this.product.xAxis = res.data.fodderPriceLineMoreChart.month
        this.product.series = res.data.fodderPriceLineMoreChart.list.map(
          (ele) => ele.values
        )
        this.product.legend = res.data.fodderPriceLineMoreChart.list.map(
          (ele) => ele.name
        )
        this.processed.series = res.data.processBreedPieChildrenChart
        this.aquaticTrendChart(this.aquatic)
        this.aquaticTrendChart(this.output)
        this.monthlyOutputChart(this.monthly)
        this.monthlyOutputChart(this.processing)
        this.productPriceTrend(this.product)
        this.processedVarietiesChart(this.processed)
      })
      .catch(() => {})
  },
  created() {},
  beforeUpdate() {
    // 组件更新之前
  },
  updated() {
    // 组件更新之后
  },
  beforeDestroy() {
    // 组件销毁前调用
    // Tips：你确认删除XX吗？
  },
  destroyed() {
    // 组件销毁后调用
    // Tips：当前组件已被删除，清空相关内容
  },
  // 数据监听
  watch: {
    // keyName: {
    // 注意：当观察的数据为对象或数组时，curVal和oldVal是相等的，因为这两个形参指向的是同一个数据对象
    // deep: true,
    //handler (curVal, oldVal) {
    // console.log(curVal, oldVal)
    //}
    // }
  },
}
</script>
<style lang="less" scoped>
@import './feed-processing.less';


</style>
